<template>
    <div class="one-way">

        <div class="flex-handler child-span">
          <validation-observer ref="observer" v-slot="{handleSubmit}">
            <form @submit.prevent="handleSubmit(handleOneWaySearch)" >
              <div class="flex-handler" >
                <div class="location sizing">
                  <div class="heading look mb-4">
                    From
                  </div>
                  <ValidationProvider name="From" rules="required" v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-combobox v-model="oneWayData.departure" :items="cities"
                                  placeholder="Departing from" prepend-inner-icon="mdi-map-marker-outline" solo
                                  hide-details flat background-color="#fdfffc" type="text" color="#004aad"
                                  :item-text="getCity"
                                  append-icon="">
                      </v-combobox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="destination sizing">
                  <div class="heading look mb-4">
                    To
                  </div>

                  <ValidationProvider name="To" rules="required" v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-combobox v-model="oneWayData.destination" :items="cities"
                                  placeholder="Arriving at" prepend-inner-icon="mdi-crosshairs-gps" solo hide-details
                                  :item-text="getCity"
                                  flat background-color="#fdfffc" color="#004aad" append-icon="" type="text">
                      </v-combobox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="date sizing">
                  <div class="heading look mb-4">
                    Date
                  </div>

                  <v-menu v-model="oneWayDate" :close-on-content-click="true" :nudge-right="40"
                          transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider name="Date" rules="" v-slot="{ classes, errors }">
                        <div class="" :class="classes">
                          <v-text-field v-model="oneWayData.travelDates" flat v-on="on"
                                        placeholder="2022-09-9" solo hide-details readonly>
                            <template #prepend-inner>
                              <v-icon class="">
                                mdi-calendar-month-outline
                              </v-icon>
                            </template></v-text-field>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </template>

                    <v-date-picker  locale="en-in" v-model="oneWayData.travelDates" no-title
                                   @input="dateMenu = false" :min="minDate">
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="seats sizing">
                  <div class="heading look mb-4">
                    Passenger(s)
                  </div>
                  <select-passengers @getTotalPassengers="getTotalPassengers" />
                </div>
                <search-button  action-type="submit"/>
              </div>

            </form>
          </validation-observer>
    </div>
      <v-progress-linear indeterminate :color="companyData.companyColor ? companyData.companyColor : '#004AAD'" v-if="oneWayLoading"></v-progress-linear>
    </div>
</template>

<script>
    import SelectPassengers from '@/components/reuseables/SelectPassengers'
    import SearchButton from "@/components/reuseables/SearchButton";
    import citiesData from '@/assets/cities.js'
    import {mapGetters} from "vuex";
    import dayjs from "dayjs";
    import {getTransportCompanyTerminals} from "@/services/api/APIService";
    export default {
        name: 'OneWaySearch',
        components: {
          SearchButton,
            SelectPassengers
        },
      data(){
          return{
            citiesData,
            cities: [],
            oneWayDate: false,
            dateMenu: false,
            minDate: new Date(new Date().getTime() + 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),
            oneWayData: {
              departure: "",
              destination: "",
              travelDates: null,
              totalPassengers: 0
            },
            oneWayLoading: false,
          }
      },
        watch:{
            companyData : {
                handler : function (val) {
                    if (Object.keys(val).length){
                        this.getAllTerminals()
                    }
                },
                immediate : true
            }
        },
      computed:{
        ...mapGetters("tripAndTravellerDetails", ["pendingTrips"]),
        ...mapGetters("companyProfile", ["companyData"])
      },
      methods:{
        scrollTo(name) {
          const el = document.getElementById(name);
          el.scrollIntoView({ behavior: "smooth" });
        },
        getTotalPassengers(total) {
          this.oneWayData.totalPassengers = total
          this.oneWayData.totalPassengers = total
        },
        async handleOneWaySearch() {
          if (this.companyData && Object.keys(this.companyData).length) {
            this.oneWayLoading = true
            this.oneWayData.departure = this.getSubstringBeforeComma(this.getCity(this.oneWayData.departure))
            this.oneWayData.destination = this.getSubstringBeforeComma(this.getCity(this.oneWayData.destination))
            this.getIsDepartureState(this.oneWayData.departure)
            this.getIsDestinationState(this.oneWayData.destination)
            this.oneWayData.page = 0
            this.oneWayData.pageSize = 15
            let data = {}
            data.transportCompanyId = this.companyData.id
            data.page = this.oneWayData.page
            data.pageSize = this.oneWayData.pageSize
            data.departureQuery = this.oneWayData.departure
            data.destinationQuery = this.oneWayData.destination
            data.departureIsCity = this.oneWayData.departureIsCity
            data.departureIsState = this.oneWayData.departureIsState
            data.destinationIsCity = this.oneWayData.destinationIsCity
            data.destinationIsState = this.oneWayData.destinationIsState
            data.currentDate = dayjs().format("YYYY-MM-DD")
            data.domain = window.location.hostname
            data.takeOffDate = this.getDateForSearchQuery(this.oneWayData.travelDates)
            await this.$store.dispatch("tripAndTravellerDetails/setPendingTrips", data).then(() => {
              this.oneWayLoading = false
              this.getQueryTrips()
            })
          }
        },
        getQueryTrips() {
            this.oneWayData.departure = this.getSubstringBeforeComma(this.getCity(this.oneWayData.departure))
            this.oneWayData.destination = this.getSubstringBeforeComma(this.getCity(this.oneWayData.destination))
          let queryTrips = []
          this.pendingTrips.forEach((trip,index) =>{
            trip.open = index === 0;
            let tripDate = dayjs(trip.takeOffDate +trip.takeOffTime).format("LLL")
            let selectedDate = dayjs(this.getUserSelectedDate(this.oneWayData.travelDates)).format("LLL")
            if (dayjs(tripDate).isSame(dayjs(selectedDate))|| dayjs( tripDate).isAfter(dayjs(selectedDate))) {
              queryTrips.push(trip)
            }
            else if (dayjs(tripDate).isAfter(dayjs().format("LLL"))){
              queryTrips.push(trip)
            }
          })
          let uniqueTrips = new Set()
          queryTrips.forEach(trip => uniqueTrips.add(JSON.stringify(trip)))
          let filteredTrips = []
          uniqueTrips.forEach(trip => filteredTrips.push(JSON.parse(trip)))

          if (filteredTrips.length) {
            sessionStorage.setItem("queryData", JSON.stringify(this.oneWayData));
            sessionStorage.setItem("queryTrips", JSON.stringify(queryTrips));
            this.$store.dispatch("tripAndTravellerDetails/setOneWayTrips", queryTrips);
            this.scrollTo("departureOneWayTrips")
          } else {
            this.$displaySnackbar({
              message: "Your search query did not match any trip"
            });
          }
        },
        getSubstringBeforeComma(str) {
          const index = str.indexOf(" - ");
          if (index === -1) {
            return str;
          } else {
            return str.substring(index + 3);
          }
        },
        getUserSelectedDate(date) {
          let todate = new Date(Date.now())
          let today = dayjs(date+"T"+todate.getHours()+":"+todate.getMinutes())
          if (date) {
            return dayjs(today).format("LLL")
          } else {
            return dayjs().format('YYYY-MM-DDTHH:mm:ss')
          }
        },
        getDateForSearchQuery(date){
          if (date){
            return date
          }
          else {
            return dayjs().format('YYYY-MM-DD')
          }
        },
        getIsDepartureState(departure){
          this.cities.forEach(city => {
            if (departure.toLowerCase() === city.city.toLowerCase() ){
              this.oneWayData.departureIsCity = true
              this.oneWayData.departureIsState= false
            }
            if (departure.toLowerCase() === city.state.toLowerCase()){
              this.oneWayData.departureIsCity = false
              this.oneWayData.departureIsState= true
            }
          })
        },
        getIsDestinationState(destination){
          this.cities.forEach(city => {
            if (destination.toLowerCase() === city.city.toLowerCase() ){
              this.oneWayData.destinationIsCity = true
              this.oneWayData.destinationIsState= false
            }
            if (destination.toLowerCase() === city.state.toLowerCase()){
              this.oneWayData.destinationIsCity = false
              this.oneWayData.destinationIsState= true
            }
          })
        },
          async getAllTerminals() {
              this.cities = []
              let uniqueCity = new Set()
                  getTransportCompanyTerminals(this.companyData.id).then(res => {
                      res.data.forEach(terminal =>{
                          let citydata = {}
                          citydata.state = this.getFirstText(terminal.address.state)
                          citydata.city = this.toSentenceCase(terminal.address.city)
                          uniqueCity.add(JSON.stringify(citydata))
                      })
                      uniqueCity.forEach(city => this.cities.push(JSON.parse(city)))
                      this.cities.sort((a, b) =>  a.city.localeCompare(b.city) ).sort((a,b) =>a.state.localeCompare(b.state) )
                  })

          },
          getCity(city){
            if (city.state){
                return city.state + " - "+city.city
            }
            else {
                return  city
            }
          },
          getFirstText(text){
              if (text) {
                  let arrayText = text.split(" ")
                  return this.toSentenceCase(arrayText[0])
              }
          },
          toSentenceCase(text){
              text = text.toLowerCase()
              return text.replace(text.charAt(0),text.charAt(0).toUpperCase()).trim()
          },

      },
      async created() {
        let queryData = JSON.parse(sessionStorage.getItem('queryData'))
        let queryTrips = JSON.parse(sessionStorage.getItem("queryTrips"))
        if ( queryData && Object.keys(queryData).length){
          this.oneWayData.departure = queryData.departure
          this.oneWayData.destination = queryData.destination
          this.oneWayData.travelDates = queryData.travelDates
          this.oneWayData.page = queryData.page
          this.oneWayData.pageSize = queryData.pageSize
          this.oneWayData.departureIsCity = queryData.departureIsCity
          this.oneWayData.departureIsState = queryData.departureIsState
          this.oneWayData.destinationIsCity = queryData.destinationIsCity
          this.oneWayData.destinationIsState = queryData.destinationIsState

        }
        if (queryTrips && Object.keys(queryTrips).length){
          await this.$store.dispatch("tripAndTravellerDetails/setOneWayTrips", queryTrips);
        }
        else {
            if (this.oneWayData.departure && this.oneWayData.destination){
               await this.handleOneWaySearch()
            }
        }
      }

    }
</script>

<style lang='scss' scoped>
    .sizing {
        padding: 10px ;
        text-align: left;
        width: 25%;
        @media (max-width:1024px) {
            text-align: left;
            width: 100%;
            padding: 15px;

        }
    }

    .look {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .heading {
        color: #2A466C;
        font-size: 16px;

        @media (max-width:768px) {
            text-align: left;
          

        }
    }

    .one-way {
        border: 2px solid rgba(42, 70, 108, 0.07);
        border-radius: 10px;
        margin: 100px 11rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        @media (max-width:1264px) {
            margin: 100px 3rem;
          align-items: flex-start;
        }

        @media (max-width:768px) {
            margin: 100px 1rem;
        }


    }

    .flex-handler {
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

      @media (max-width:1024px) {
        flex-direction: column;
        width: 100%;
        padding: 0;
        align-items: flex-start;
      }
    }

    .child-span > span{
      width: 100%;
    }
</style>